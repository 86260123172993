import React from "react";
import BFirst from "./BFirst/index";
import BSecond from "./BSecond/index";
import phone from "../../../assets/phone.png";
import laptop2 from "../../../assets/laptop2.png";
const index = () => {
  return (
    <>
      <BFirst
        id="sectionI"
        bgcolor={"#f7f6f7"}
        subtitle="Secure & Anonymous Solutions"
        title="Electronic Feedback Processing"
        para1="Authentic audience sentiment is obtained more easily in a context of secure anonymity. People often wish to privately express their thoughts in the form of compliments, complaints, and often constructive criticism. A system designed to offer a safe forum for people to fully convey their thoughts is critical to the success of organizations committed to continuous improvement."
        para2="Quantum Peg has established experience in facilitating the efficient, automated processing of audience expression. Whether you need a customized vote-processing web interface, survey processing, or other audience sentiment solution, partner with us to securely process your audience's candid opinions."
        buttonText="Contact Us"
        imageSrc={phone}
        altText="phone"
      />
      <BSecond />
      <BFirst
        id="sectionIII"
        bgcolor={"#FFF"}
        subtitle="we make ideas come to life"
        title="Custom Software Development"
        para1="Regardless of how many software projects have been written, your needs are unique. Technology is always changing and common-off-the-shelf products and software packages do not always fit the bill.And what if you want to integrate your software and an Internet-of-things (IoT) hardware device, or perhaps you want to process SMS text data?"
        para2="You will want to partner with a flexible software engineering team with experience interfacing with hardware.Get in touch with us and we will gladly review your requirements and help brainstorm a customized solution."
        buttonText="schedule a Consultation"
        imageSrc={laptop2}
        altText="laptop2"
      />
    </>
  );
};

export default index;
