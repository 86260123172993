import React from "react";
import "./bsecond.css";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Btn from "../../../Shared/Button/Btn";
import laptop from "../../../../assets/laptop.png";
const index = () => {
  return (
    <>
      <div id="sectionII" className="banner_main_Black">
        <Container>
          <div className="banner_Data_Box_Black">
            <Row className="Bannerdata_main_row_Black ">
              <Col>
                <div className="BFirst_image_Black">
                  <img src={laptop} alt={"laptop"} className="img-fluid" />
                </div>
              </Col>
              <Col>
                <div className="banner_data_Black mt-5 mt-md-0">
                  <p className="widget_title text-start text-white">
                    we’re quick and thorough
                  </p>
                  <h2 className="data_title regular_title text-white">
                    Test Automation Architecture
                  </h2>
                  <div>
                    <p className="paragraph_Black text-white">
                      Test Automation is critical to an effective software
                      development lifecycle. Rapid, iterative deployments are
                      only as successful as the quality of the code being
                      deployed. Good test automation requires architectural
                      design and development skill spanning across the breadth
                      of the entire infrastructure.
                    </p>
                    <p className="paragraph_Black text-white">
                      Whether your project contains graphical user interfaces
                      (GUI) and/or application programming interfaces (API), we
                      have you covered.We cover the test path from planning to
                      results, architecting and developing a front-to-back test
                      automation solution.
                    </p>
                  </div>
                  <div>
                    <Btn title="FIND OUT MORE" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default index;
