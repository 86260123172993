import React from "react";
import "./service.css";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import servicesData from "../../ConstantData/serviceData";
const Service = () => {
  return (
    <div id="service" className="service_main">
      <Container>
        <div className="content">
          <p className="widget_title">creative &amp; tenacious</p>
          <div className="regular_title">
            <h1 className="text-center">Our Services</h1>
          </div>
        </div>
        <div className="Service_Box">
          <Row>
            {servicesData.map((service) => (
              <Col key={service.id} col={12} md={4}>
                <div className="Service_Box-item">
                  <div className="Service_img">
                    <img
                      src={service.imageSrc}
                      alt={service.altText}
                      className="img-fluid w-100"
                    />
                  </div>
                  <h5>{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Service;
