import "./App.css";
import Layout from "./Home";
// import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <>
      <Layout />
    </>
  );
}

export default App;
