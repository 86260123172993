import React from "react";
import "./bfirst.css";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Btn from "../../../Shared/Button/Btn";
const index = ({
  subtitle,
  title,
  para1,
  para2,
  buttonText,
  imageSrc,
  altText,
  bgcolor,
  id,
}) => {
  return (
    <>
      <div
        id={`${id}`}
        className="banner_main"
        style={{ backgroundColor: `${bgcolor}` }}
      >
        <Container>
          <div className="banner_Data_Box">
            <Row className="Bannerdata_main_row">
              <Col>
                <div className="banner_data">
                  <p className="widget_title text-start">{subtitle}</p>
                  <h2 className="data_title regular_title">{title}</h2>
                  <div>
                    <p className="paragraph">{para1}</p>
                    <p className="paragraph">{para2}</p>
                  </div>
                  <div>
                    <Btn title={buttonText} />
                  </div>
                </div>
              </Col>
              <Col>
                <div className="BFirst_image">
                  <img src={imageSrc} alt={altText} className="img-fluid" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default index;
