import processing from "../../assets/processing.svg"
import service from "../../assets/service.svg"
import dev from "../../assets/dev.svg"

const servicesData = [
    {
        id: 1,
        title: "Electronic Feedback Processing",
        description: "We're experienced in the efficient, automated processing of audience expression. Whether its a customized vote-processing web interface, survey processing, or other audience sentiment solution, we can securely process your audience's candid opinions.",
        imageSrc: processing,
        altText: "process"
    },
    {
        id: 2,
        title: "Test automation architecture",
        description: "Whether your project contains graphical user interfaces (GUI) and/or application programming interfaces (API), we cover the test path from planning to results, architecting and developing a front-to-back test automation solution. ",
        imageSrc: service,
        altText: "service"
    },
    {
        id: 3,
        title: "Custom Software Development",
        description: "Perhaps you need to integrate your software and an Internet- of - things(IoT) hardware device or process SMS text data.We are a flexible software engineering team with experience interfacing with hardware.Let us help brainstorm a customized solution. ",
        imageSrc: dev,
        altText: "dev"
    },

];

export default servicesData;
