import React, { useEffect, useState } from "react";
import "./hero.css";
import Btn from "../../Shared/Button/Btn";
import Container from "react-bootstrap/Container";
const Hero = () => {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div id="home" className="hero_main">
      <Container>
        <div className="hero_content_wrap">
          <div className="hero_subhead">
            Invite us to be your ally and
            <br />
            <div className="main_title">
              <h2>Gain a strategic advantage.</h2>
            </div>
          </div>
          <div className="mt-3 mt-md-4">
            <Btn title="Learn more" />
          </div>
        </div>
      </Container>
      {/* scroll to top */}
      <button
        id="button"
        className={`scroll-to-top-button ${showButton ? "show" : ""}`}
        onClick={scrollToTop}
      ></button>
    </div>
  );
};

export default Hero;
