import React, { useEffect, useState } from "react";
import "./header.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import nav from "../../../assets/nav.png";
import logodark from "../../../assets/logodark.png";
import { HashLink } from "react-router-hash-link";
const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [scrolltopdata, setscrolltopdata] = useState("");
  const handleButtonClick = () => {
    setIsActive(!isActive);
  };
  const handleLinkClick = () => {
    setIsActive(false);
  };
  const handleDropOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleLinkCLose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 15) {
        setscrolltopdata("");
      } else {
        setscrolltopdata("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={`header_main ${scrolltopdata}`}>
      <Navbar expand="md" collapseOnSelect>
        <Container>
          <Navbar.Brand href="#">
            <img
              src={logodark}
              alt="nav"
              className="img-fluid block d-md-none w-full"
            />
            <img
              src={scrolltopdata === "scrolled" ? logodark : nav}
              alt="nav"
              className="img-fluid d-none d-md-block"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => {
              handleButtonClick();
            }}
            className={`${isActive ? "open" : ""}`}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          <Navbar.Collapse>
            <Nav className="my-2 my-lg-0">
              <Nav.Link href="#home" onClick={handleLinkClick}>
                Home
              </Nav.Link>
              <div className="main_drop">
                <Nav.Link href="#service" onClick={handleLinkClick}>
                  <span>Services</span>
                  <ul className={`dropdown-menu ${open ? "block" : "d-none"}`}>
                    <li className="drop_li">
                      <HashLink
                        spy="true"
                        smooth={true}
                        duration={500}
                        to="/#sectionI"
                        onClick={handleLinkCLose}
                        className="Dropdown-item Droplink"
                      >
                        Electronic Feedback Processing
                      </HashLink>
                    </li>
                    <li className="drop_li">
                      <HashLink
                        spy="true"
                        duration={500}
                        smooth={true}
                        onClick={handleLinkCLose}
                        to="/#sectionII"
                        className="Dropdown-item Droplink"
                      >
                        Test Automation Architecture
                      </HashLink>
                    </li>
                    <li className="drop_li">
                      <HashLink
                        spy="true"
                        smooth={true}
                        duration={500}
                        onClick={handleLinkCLose}
                        to="/#sectionIII"
                        className="Dropdown-item Droplink"
                      >
                        Custom Software Development
                      </HashLink>
                    </li>
                  </ul>
                </Nav.Link>
                <div
                  className={`btn-div ${open ? "rotate" : ""} d-md-none`}
                  onClick={handleDropOpen}
                >
                  <p className="GreatedIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.3em"
                      height="1.3em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6l6 6z"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <Nav.Link href="#contact" onClick={handleLinkClick}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
