import React from "react";
import "./footer.css";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
      <div className="footer_main">
        <div className="container">
            <p className="mb-0">
                © {currentYear} Quantum Peg Inc. · All Rights Reserved · Site Design by&nbsp;
                <a href="https://puremediainc.com/" target="_blank" rel="noopener noreferrer">
                    pure
                </a>
            </p>
        </div>
      </div>
  );
}

export default Footer;
