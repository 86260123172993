/* global grecaptcha */
import React, { useState } from "react";
import "./contact.css";
import Container from "react-bootstrap/Container";
import { Row, Col, Spinner } from "react-bootstrap";
import Btn from "../../Shared/Button/Btn";
const Contact = () => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for tracking submission status


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    setIsSubmitting(true); // Start submission, show spinner

    try {
      const token = await grecaptcha.enterprise.execute('6LcX_JopAAAAAHcoa4XZcpoCxVCHr2_MeITmzWj7', {action: 'submit'});
      // Include the token in your contact data
      const contactData = {
        first: firstName,
        last: lastName,
        email: email,
        comment: comment,
        token: token, // Add this line to include the reCAPTCHA token in your payload
      };

      const response = await fetch('https://ohpyfiserf.execute-api.us-east-1.amazonaws.com/prod/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contactData),
      });

      setIsSubmitting(false); // End submission, hide spinner

      if (!response.ok) {
        console.log(response)
        {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      const result = await response.json();
      console.log('Success:', result);
      alert('Message sent successfully!');
    } catch (error) {
      setIsSubmitting(false); // Ensure spinner is hidden if there's an error
      console.error('Error:', error);
      alert('Failed to send the message.');
    }
  };

return (
    <>
      <div id="contact" className="contect_main">
        <Container>
          <form onSubmit={handleSubmit}>
            <div className="contact_content">
              <h3 className="widget_title">We’d Love to Hear From You</h3>
              <div className="regular_title">
                <h1 className="text-center">Contact Us</h1>
              </div>
              <p>
                You may dial us direct at (503) 805-9960. Or fill out our form and
                we'll respond within 24 hours.
              </p>
            </div>
            <div className="contact_data_box">
              <Row>
                <Col md={4}>
                  <div>
                    <label htmlFor="First Name" className="labels">
                      First Name&nbsp;<span className="text-danger">*</span>
                    </label>
                    <input type="text"
                           className="form-control"
                           id="First Name"
                           value={firstName}
                           onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label htmlFor="Last Name" className="labels">
                      Last Name&nbsp;<span className="text-danger">*</span>
                    </label>
                    <input type="text"
                           className="form-control"
                           id="Last Name"
                           value={lastName}
                           onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label htmlFor="email" className="labels">
                      Email Address&nbsp;<span className="text-danger">*</span>
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="name@example.com"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div>
                    <label htmlFor="exampleFormControlTextarea1" className="labels">
                      Comments / Questions&nbsp;
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </div>
                </Col>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? (
                          <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                            Sending...
                          </>
                      ) : (
                          "Send Message"
                      )}
                    </button>
                  </div>
              </Row>
            </div>
          </form>
        </Container>
      </div>
    </>
);
};

export default Contact;
