import React from "react";
import Footer from "./components/Shared/Footer/Footer";
import Hero from "./components/Sections/Hero/Hero";
import Service from "./components/Sections/OurService/Service";
import InfoBanner from "./components/Sections/InfoBanner/index";
import Contact from "./components/Sections/ContactUs/Contact";
import Header from "./components/Shared/Header/Header";
const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Service />
      <InfoBanner />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
